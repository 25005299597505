/* eslint-disable react/jsx-no-target-blank */
import { Button, Input } from '@promptlylabs/skeleton';
import { onClickLoginIdSubmit } from 'actions/datacollection';
import { KIOSK_COOKIE } from 'constants/app';
import { PROVIDERS_URL, SIGNUP_URL } from 'constants/urls';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isStatusOk } from 'utils/axios';
import { fullNumber, validateEmail, validatePhone, validateSize, withoutLetters } from 'utils/validations';
import { getAuthProvider } from '../actions/index';
import { PAGE_PASSWORD, PAGE_PASSWORD_RESET } from '../pages';

export class LoginForm extends React.Component {
  state = {
    userID: this.props.user.id,
    nextPage: PAGE_PASSWORD,
    type: 'EMAIL',
    inputError: undefined,
    showSignUp: document.cookie.includes(KIOSK_COOKIE)
  };

  getUserType = () => this.state.type;

  getGeoLocation = () =>
    fetch('https://api.ipdata.co?api-key=cf58271cad664387c39ebed1931fcd5486c1972be5df73d2acbf47a2').then(response =>
      response.json()
    );

  handleInputChange = event => {
    this.setState({
      userID: event.target.value,
      inputError: undefined
    });
  };

  proceedToPassword = (userType = 'EMAIL', phoneData = {}, forgotPassword = false) => {
    const value = this.state.userID.trim().toLocaleLowerCase();

    this.setState({ userID: value });

    this.props.handleClick(undefined, {
      value,
      type: userType,
      nextPage: forgotPassword ? PAGE_PASSWORD_RESET : this.state.nextPage,
      phoneData,
      prevPage: undefined
    });
  };

  checkIdentityProvider = (data, forgotPassword, type, phoneData = undefined) => {
    this.props.getAuthProvider(PROVIDERS_URL, data).then(response => {
      if (isStatusOk(response)) {
        if (response.data.type === 'local') {
          this.proceedToPassword(type, phoneData, forgotPassword);
          return;
        }

        const queryParams = new URLSearchParams(window.location.search).toString();
        let separator = '';
        if (queryParams) {
          separator = response.data.url.includes('?') ? '&' : '?';
        }

        window.location.href = response.data.url + separator + queryParams;
      }
    });
  };

  handleFormSubmit = (event, forgotPassword = false) => {
    if (event !== undefined) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.props.onClickLoginIdSubmit();
    const value = this.state.userID.trim().toLocaleLowerCase();
    const isEmailValid = validateEmail(value);
    const [isValidatePhone, PhoneData] = validatePhone(value);

    if (isEmailValid) {
      this.setState({ type: 'EMAIL' });
      if (!window.IDENTITY_PROVIDERS_ENABLED) {
        this.proceedToPassword('EMAIL', undefined, forgotPassword);
        return;
      }
      // calls api to get the authentication provider
      const data = { email: value };
      return this.checkIdentityProvider(data, forgotPassword, 'EMAIL');
    }

    if (withoutLetters(value) && validateSize(value)) {
      if (isValidatePhone) {
        this.setState({ type: 'PHONE' });
        if (!window.IDENTITY_PROVIDERS_ENABLED) {
          this.proceedToPassword('PHONE', PhoneData, forgotPassword);
        } else {
          // calls api to get the authentication provider
          const data = { phone: fullNumber(PhoneData) };
          this.checkIdentityProvider(data, forgotPassword, 'PHONE', PhoneData);
        }
      } else {
        this.getGeoLocation()
          .then(response => {
            const [isvalid, _PhoneData] = validatePhone(value, response.country_code);
            this.setState({ type: 'PHONE' });
            if (!window.IDENTITY_PROVIDERS_ENABLED) {
              this.proceedToPassword(
                'PHONE',
                isvalid
                  ? _PhoneData
                  : {
                      phone: value,
                      country: response.country_code
                    },
                forgotPassword
              );
            } else {
              const data = {
                phone: isvalid
                  ? fullNumber(_PhoneData)
                  : fullNumber({
                      phone: value,
                      country: response.country_code
                    })
              };
              this.checkIdentityProvider(data, forgotPassword, 'PHONE', _PhoneData);
            }
          })
          .catch(message => {
            // something is wrong with external api, log to sentry
            window.Sentry.captureException(message);
            this.setState({ type: 'PHONE' });
            this.proceedToPassword('PHONE', { phone: value, country: 'PT' }, forgotPassword);
          });
      }
    } else {
      this.setState({ inputError: this.props.t('Please insert a valid email or phone number') });
    }
  };

  render() {
    return this.props.show ? (
      <div className="prom-login-forms-wrapper">
        <h1 className="prom-login-content__title">{this.props.t('Welcome')}</h1>
        <p>
          <span>
            {this.props.t(
              'Sign in below as health professional using your credentials. In case you are a patient, please'
            )}{' '}
            <a rel="noopener noreferrer" href={`${window.PATIENT_APP_URL}`}>
              {this.props.t('click here')}
            </a>
            {'.'}
          </span>
          <span className="prom-login-content__more-info">
            {this.props.t('To know more about Promptly, please visit')}{' '}
            <a rel="noopener noreferrer" target="_blank" href="https://promptlyhealth.com">
              https://promptlyhealth.com
            </a>
          </span>
        </p>
        <form onSubmit={this.handleFormSubmit}>
          <Input
            id="user-id"
            label={this.props.t('Email or phone number')}
            fullWidth
            value={this.state.userID}
            onChange={this.handleInputChange}
            error={this.state.inputError}
            helperText={this.state.inputError || undefined}
          />
          <div className="prom-login-content__actions mg-top-50px flex">
            {this.state.showSignUp && (
              <a href={SIGNUP_URL} id="signup">
                {this.props.t('Sign Up')}
              </a>
            )}

            <a onClick={e => this.handleFormSubmit(e, true)} role="button" tabIndex="-1" id="forgot-password">
              {this.props.t('Forgot password?')}
            </a>

            <Button color="primary" variant="contained" onClick={this.handleFormSubmit}>
              {this.props.t('Next')}
            </Button>
          </div>
        </form>
      </div>
    ) : null;
  }
}

LoginForm.defaultProps = {
  show: true
};

LoginForm.propTypes = {
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  handleAuthProvider: PropTypes.func.isRequired,
  getAuthProvider: PropTypes.func.isRequired,
  onClickLoginIdSubmit: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAuthProvider,
      onClickLoginIdSubmit
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(LoginForm);
