import { DateUtils } from '@promptlylabs/ui-kit';

// Reminder: moment object is mutable!!!

export const {
  DATE_NOW,
  DATE_TEST,
  DATE_TODAY,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIMEZONE,
  isDatePast,
  isDateFuture,
  isDateValid,
  dateOverdueFromToday,
  dateDiffInWords,
  moment
} = DateUtils;

/**
 * Util to get week difference relative to given date string
 * @param {string} baseDate date string
 * @returns {number} weeks difference
 */
export function getWeekDiff(baseDate = DATE_NOW.format()) {
  const week = ~~(DATE_NOW.diff(baseDate, 'days') / 7);
  if (week >= 42) {
    return 42;
  }
  return week + 1;
}

export const sortByDates = (a, b) => new Date(a) - new Date(b);

/**
 * gets a list with the last 12 months as YYYY-MM
 */
export const getLast12Months = () => {
  const firstMonth = moment().subtract(12, 'months');
  const currentMonth = moment();

  const months = [];

  while (currentMonth.isSameOrAfter(firstMonth)) {
    months.push(currentMonth.format('YYYY-MM'));
    currentMonth.subtract(1, 'months');
  }
  return months;
};

/**
 * Gets a list with moments between two dates
 * @param {[]} dates List with moments objects
 * @param {string} day Value of the day
 * @param {string} begin Initial hour
 * @param {string} end Final Hour
 * @param {string} yearMonth String with year and month as YYYY/MM
 * @param {string} timezone Patient's timezone
 */
export const getDatesByHours = (dates, day, begin, end, yearMonth, timezone = '') =>
  dates.filter(d => {
    const initialTime = moment.tz(`${yearMonth}-${day} ${begin}`, timezone);
    const finalTime = moment.tz(`${yearMonth}-${day} ${end}`, timezone);
    const measurementTime = moment.tz(d, timezone);
    return measurementTime.isBetween(initialTime, finalTime);
  });

/**
 * Gets a list with month days betweeen two dates
 * @param {*} startDate Initial date
 * @param {*} endDate Final date
 */
export const getDaysBetweenDates = (startDate, endDate) => {
  if (startDate && endDate) {
    const dates = [];
    const now = endDate.clone();

    while (now.isSameOrAfter(startDate)) {
      dates.push(now.format('DD/MM'));
      now.subtract(1, 'days');
    }
    return dates;
  }
};

/**
 * Given a date and receiving an offset number calculates the new date
 * @param {string} selectedDate date string
 * @param {number} delta number ex: (-1 or 1)
 */

export const datetoString = (selectedDate, delta) => {
  const func = delta > 0 ? 'add' : 'subtract';
  return moment(selectedDate)[func](Math.abs(delta), 'days').format('YYYY-MM-DD');
};

export const YEAR_MONTH_FORMAT = 'YYYY-MM';

/**
 * Given a date, convert to moment if needed and get only the default date format
 * @param {string} date date string
 */

export const convertDateToDefault = date =>
  moment.isMoment(date) ? date.format(DEFAULT_DATE_FORMAT) : moment(date).format(DEFAULT_DATE_FORMAT);

export const convertDateStringToPrintableString = date => moment(date).format('DD/MM/YYYY');
